export default {
    data: function() {
        return {
            regexExcludeHtml: "(?![^<]*>|[^<>]*<\/)"
        }
    },
    methods: {

        getPattern(rule){
            return rule.content
                .toLowerCase()
                .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        },

        getRuleRegex(rule){
            return new RegExp('\\b'+this.getPattern(rule)+'\\b', 'gi');
        },

        // Matching rules are ordered by companies
        // so the first one will be the one with a company if it exists
        getActiveMatchingRules(matchingRules){
            return matchingRules?.[Object.keys(matchingRules)[0]];
        },

        isRulesConflictingForString(string, prevRules, newRules){
            if(!prevRules || !newRules) return false;

            string = string.toLowerCase();

            for(const newRule of newRules){

                if(newRule.mode === 'out') continue;

                const newRegexp = this.getRuleRegex(newRule);

                if(!newRegexp.test(string)) continue;

                for(const prevRule of prevRules){
                
                    if(prevRule.mode === 'out') continue;
    
                    const prevRegexp = this.getRuleRegex(prevRule);
    
                    if(!prevRegexp.test(string)) continue;

                    if(
                        newRule.content.toLowerCase() === prevRule.content.toLowerCase()
                        || !newRule.content.toLowerCase().includes(prevRule.content.toLowerCase())
                    ){
                        return true;
                    }
                }

            }

            return false;
        }

    }
};