<template>
    <div class="text-center">
        <lottie-player src="https://assets10.lottiefiles.com/private_files/lf30_gnbzdsjh.json" background="transparent" speed="1" style="margin: auto;width: 100px; height: 100px;" loop autoplay></lottie-player>
        <p>{{ $t("commons.loading") }}</p>
    </div>   
</template>

<script>
    export default {
        name: "loader"
    };
</script>
