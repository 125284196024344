var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("l1-modal", {
    attrs: {
      width: _vm.modalWidth,
      noTitle: "",
      loading: _vm.isLoading,
      isCancellable: false,
      persistent:
        _vm.isLoading || (_vm.actualStep == 1 && _vm.comingFromRegister),
      isClosable: _vm.actualStep == 2,
      actions: _vm.actualStep == 1 && !_vm.comingFromRegister,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _vm.actualStep == 1 && _vm.comingFromRegister
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-right gray-40--text font-weight-regular text-caption",
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "https://faq.licence.one/en/article/what-is-a-licenceone-demonstration-account-1xyw9tz/",
                            },
                          },
                          [
                            _c(
                              "icon-base",
                              { attrs: { iconColor: "#818C98" } },
                              [_c("icon-info")],
                              1
                            ),
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("commons.learn-more")) +
                                "\n                "
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column justify-space-between align-center",
                      },
                      [
                        _c("v-img", {
                          attrs: {
                            height: "160",
                            contain: true,
                            src: _vm.bikeSrc,
                          },
                        }),
                        _vm._v(" "),
                        _c("h2", { staticClass: "fw-7 mt-8 f-2" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("sandbox.welcome")) +
                              "\n                "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-center mt-2 mb-10" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t("sandbox.switch-to-real-data-modal.desc")
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-container",
                      { staticClass: "px-0" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "border-all rounded-lg gray-70--text pa-6 relative d-flex flex-column justify-space-between",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "black--text d-flex flex-column",
                                  },
                                  [
                                    _c("h3", { staticClass: "fw-7 mb-3 f-4" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "sandbox.switch-to-real-data-modal.start-with-fake-data"
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "mb-4 gray-40--text sandbox-card__text f-6",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "sandbox.switch-to-real-data-modal.start-with-fake-data-desc"
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("l1-button", {
                                  attrs: {
                                    text: _vm.$t("commons.buttons.start"),
                                  },
                                  on: { action: _vm.startPlaySandbox },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-spacer"),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "border-all rounded-lg gray-70--text pa-6 relative d-flex flex-column justify-space-between",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "black--text d-flex flex-column",
                                  },
                                  [
                                    _c("h3", { staticClass: "fw-7 mb-3 f-4" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "sandbox.switch-to-real-data-modal.start-with-real-data"
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "mb-4 gray-40--text sandbox-card__text f-6",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "sandbox.switch-to-real-data-modal.start-with-real-data-desc"
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("l1-button", {
                                  attrs: {
                                    text: _vm.$t("commons.buttons.start"),
                                  },
                                  on: { action: _vm.startWithRealData },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.actualStep == 1 && !_vm.comingFromRegister
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-right gray-40--text font-weight-regular text-caption",
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: "https://faq.licence.one/en/article/what-is-a-licenceone-demonstration-account-1xyw9tz",
                          },
                        },
                        [
                          _c(
                            "icon-base",
                            { attrs: { iconColor: "#818C98" } },
                            [_c("icon-info")],
                            1
                          ),
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("commons.learn-more")) +
                              "\n                "
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column justify-space-between align-center",
                    },
                    [
                      _c("v-img", { attrs: { src: _vm.imagePath } }),
                      _vm._v(" "),
                      _c("h2", { staticClass: "display-3 mt-8" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t("sandbox.ready-to-analyse-applications")
                            ) +
                            "\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-center mt-8" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t("sandbox.no-comeback-possible-" + _vm.version)
                        ) +
                        "\n                "
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("sandbox.no-comeback-possible-common")) +
                        "\n            "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.actualStep == 2
              ? _c("div", [
                  _c("div", { staticClass: "pt-5 pb-8 text-center" }, [
                    _c("img", { attrs: { src: _vm.mailSrc } }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "h2",
                    { staticClass: "text-center heading--text display-3 pb-4" },
                    [_vm._v(_vm._s(_vm.$t("security.confirm-email.confirm")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center d-flex flex-column justify-center align-center mt-2",
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("security.confirm-email.sent")) +
                            " "
                        ),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.user.email.toLowerCase())),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-10" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("security.confirm-email.security")) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-center align-center mb-10",
                          staticStyle: { gap: "24px" },
                        },
                        [
                          _c("img", { attrs: { src: _vm.gmailSrc } }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "grey-8--text border-link pt-2 cpointer",
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo(
                                    "https://mail.google.com/mail/u/0/#search/from%3Ahello%40licence.one+in%3Aanywhere"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("security.password.link")) +
                                  "\n                        "
                              ),
                              _c(
                                "icon-base",
                                { attrs: { "icon-name": "icon-arrow-right" } },
                                [_c("icon-arrow-right", { tag: "component" })],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "pb-0 mb-0" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t("security.confirm-email.didnt-receive")
                            ) +
                            " "
                        ),
                        _c(
                          "strong",
                          {
                            staticClass: "cpointer",
                            on: { click: _vm.sendEmail },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("security.confirm-email.send-new"))
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _vm.actualStep == 1 && !_vm.comingFromRegister
              ? _c(
                  "v-btn",
                  {
                    staticClass: "mr-2",
                    attrs: { color: "grey-8", text: "" },
                    on: {
                      click: function ($event) {
                        _vm.opened = false
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("commons.buttons.back")) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.actualStep == 1 && !_vm.comingFromRegister
              ? _c("l1-button", {
                  attrs: { text: _vm.$t("commons.buttons.next") },
                  on: { action: _vm.switchToRealData },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.opened,
      callback: function ($$v) {
        _vm.opened = $$v
      },
      expression: "opened",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }