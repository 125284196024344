<template>
    <l1-modal :width="modalWidth" noTitle v-model="opened" :loading="isLoading" :isCancellable="false" :persistent="isLoading || (actualStep == 1 && comingFromRegister)" :isClosable="actualStep == 2" :actions="actualStep == 1 && !comingFromRegister">
        <template v-slot:content>
            <div v-if="actualStep == 1 && comingFromRegister">
                <div class="text-right gray-40--text font-weight-regular text-caption">
                    <a target="_blank" href="https://faq.licence.one/en/article/what-is-a-licenceone-demonstration-account-1xyw9tz/">
                        <icon-base iconColor="#818C98">
                            <icon-info></icon-info>
                        </icon-base>
                        {{ $t('commons.learn-more') }}
                    </a>
                </div>
                <div class="d-flex flex-column justify-space-between align-center">
                    <v-img height="160" :contain="true" :src="bikeSrc"></v-img>
                    <h2 class="fw-7 mt-8 f-2">
                        {{ $t('sandbox.welcome') }}
                    </h2>
                </div>
                <p class="text-center mt-2 mb-10">
                    {{ $t('sandbox.switch-to-real-data-modal.desc') }}
                </p>
                <v-container class="px-0">
                    <v-row>
                        <v-col class="border-all rounded-lg gray-70--text pa-6 relative d-flex flex-column justify-space-between">
                                <div class="black--text d-flex flex-column">
                                    <h3 class="fw-7 mb-3 f-4">
                                        {{ $t('sandbox.switch-to-real-data-modal.start-with-fake-data') }}
                                    </h3>
                                    <span class="mb-4 gray-40--text sandbox-card__text f-6">
                                        {{ $t('sandbox.switch-to-real-data-modal.start-with-fake-data-desc') }}
                                    </span>
                                </div>
                                <l1-button
                                    @action="startPlaySandbox"
                                    :text="$t('commons.buttons.start')"
                                ></l1-button>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class="border-all rounded-lg gray-70--text pa-6 relative d-flex flex-column justify-space-between">
                                <div class="black--text d-flex flex-column">
                                    <h3 class="fw-7 mb-3 f-4">
                                        {{ $t('sandbox.switch-to-real-data-modal.start-with-real-data') }}
                                    </h3>
                                    <span class="mb-4 gray-40--text sandbox-card__text f-6">
                                        {{ $t('sandbox.switch-to-real-data-modal.start-with-real-data-desc') }}
                                    </span>
                                </div>
                                <l1-button
                                    @action="startWithRealData"
                                    :text="$t('commons.buttons.start')"
                                ></l1-button>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <div v-if="actualStep == 1 && !comingFromRegister">
                <div class="text-right gray-40--text font-weight-regular text-caption">
                    <a target="_blank" href="https://faq.licence.one/en/article/what-is-a-licenceone-demonstration-account-1xyw9tz">
                        <icon-base iconColor="#818C98">
                            <icon-info></icon-info>
                        </icon-base>
                        {{ $t('commons.learn-more') }}
                    </a>
                </div>
                <div class="d-flex flex-column justify-space-between align-center">
                    <v-img :src="imagePath"></v-img>
                    <h2 class="display-3 mt-8">
                        {{ $t('sandbox.ready-to-analyse-applications') }}
                    </h2>
                </div>
                <p class="text-center mt-8">
                    {{ $t('sandbox.no-comeback-possible-' + version) }}
                    <br/><br/>
                    {{ $t('sandbox.no-comeback-possible-common') }}
                </p>
            </div>
            <div v-if="actualStep == 2">
                <div class="pt-5 pb-8 text-center">
                    <img :src="mailSrc">
                </div>
                <h2 class="text-center heading--text display-3 pb-4">{{ $t('security.confirm-email.confirm') }}</h2>
                <div class="text-center d-flex flex-column justify-center align-center mt-2">
                    <p>
                        {{ $t('security.confirm-email.sent') }} <strong>{{ user.email.toLowerCase() }}</strong>
                    </p>
                    <p class="mb-10">
                        {{ $t('security.confirm-email.security') }}
                    </p>
                    <div class="d-flex justify-center align-center mb-10" style="gap: 24px;">
                        <img :src="gmailSrc">
                        <div @click="redirectTo('https://mail.google.com/mail/u/0/#search/from%3Ahello%40licence.one+in%3Aanywhere')" class="grey-8--text border-link pt-2 cpointer">
                            {{ $t('security.password.link') }}
                            <icon-base icon-name="icon-arrow-right">
                                <component :is="'icon-arrow-right'"></component>
                            </icon-base>
                        </div>
                    </div>
                    <p class="pb-0 mb-0">
                        {{ $t('security.confirm-email.didnt-receive') }} <strong @click="sendEmail" class="cpointer">{{ $t('security.confirm-email.send-new') }}</strong>
                    </p>
                </div>
            </div>
        </template>
        <template v-slot:actions >
            <v-btn 
                v-if="actualStep == 1 && !comingFromRegister"
                color="grey-8"
                class="mr-2"
                text
                @click="opened = false"
            >
                {{ $t('commons.buttons.back') }}
            </v-btn>
            <l1-button
                v-if="actualStep == 1 && !comingFromRegister"
                :text="$t('commons.buttons.next')"
                @action="switchToRealData"
            ></l1-button>
        </template>
    </l1-modal>
</template>

<script>
    import mixin from '../../Mixins/mixin.js';
    import {mapGetters} from 'vuex';
    import L1Modal from "./Modal";
    import L1Button from "../../components/form/button/Button";
    import IconBase from '../../components/IconBase';
    import IconInfo from "../../components/icons/IconInfo";
    import IconArrowRight from "../../components/icons/IconArrowRight";
    import bikeSrc from '@app/images/appsumo-bike-illustration.svg';
    import mailSrc from '@app/images/mail.svg';
    import gmailSrc from '@app/images/gmail.svg';
    import magnifierSrc from '@app/images/man-w-magnifier.svg';
    import boxSrc from '@app/images/box-explode.svg';

    export default {
        mixins: [mixin],
        components: {
            L1Modal,
            L1Button,
            IconBase,
            IconInfo,
            IconArrowRight
        },
        props: {

            value: {
                type: Boolean,
            },

            version: {
                type: String,
                default: 'natural',
                validator: (value) => {
                    return ['natural', 'illegal'].indexOf(value) !== -1;
                }
            },

            step: {
                type: Number,
                default: 1
            }
        },
        mounted(){
            this.$emit('mounted');
        }, 
        data: function(){
            return {
                bikeSrc: bikeSrc,
                mailSrc: mailSrc,
                gmailSrc: gmailSrc,
                magnifierSrc: magnifierSrc,
                boxSrc: boxSrc,
                actualStep: this.step,
                isLoading: false,
                isFirstEmailSend: false,
                modalWidth: '674px'
            };
        },

        watch: {
            actualStep() {
                if(this.actualStep===1) {
                    this.modalWidth = '674px'
                } else if (this.actualStep===2) {
                    this.modalWidth = '520px'
                } else {
                    this.modalWidth = '416px'
                }
            },
            step(val) {
                this.actualStep = val
            }
        },

        computed: {
            opened: {
                get(){
                    return this.value;
                },
                set(value){
                    this.$emit('input', value);
                }
            },

            comingFromRegister() {
                return window.localStorage.getItem('onboarded') === '0'
            },

            imagePath() {

                let imagePath = '';
                switch(this.version){

                    case 'natural':
                        imagePath = this.magnifierSrc;
                        break;

                    case 'illegal':
                        imagePath = this.boxSrc;
                        break;
                }

                return imagePath;
            },

            ...mapGetters("user", {
                user: "get",
            }),
             ...mapGetters('company', {
                company : 'get', 
                hasError: 'hasError',
            }),
            ...mapGetters("user", {
                user: "get",
            }),
        },

        methods: {

            startPlaySandbox() {
                this.opened = false
            },

            startWithRealData() {
                this.sendEmail();
                this.actualStep = 2;
            },

            async switchToRealData() {
                if(!this.user.isAccountValidated) {
                    this.sendEmail();
                    this.actualStep = 2;
                } else {
                    await this.$store.dispatch("company/update", {onboardingStep: 1});
                    this.$router.push({
                        name: 'onboarding.user',
                    });
                }
            },

            nextStepFirstEmail() {
                this.actualStep += 1
                if(!this.isFirstEmailSend) {
                    this.sendEmail()
                    this.isFirstEmailSend = true
                }
            },

            async sendEmail() {
                this.$store.dispatch("snackbar/setSnack", {
                    message: this.$t('users.new.invitation-resend'), 
                }); 
                await this.$store.dispatch("user/resendConfirmation", {user: this.user});
            },

        },

    }
</script>
<style lang="scss" scoped>
    .sandbox-card__text {
        line-height: 1.143rem;
        max-width: 176px;
    }
    :deep(.spacer) {
        flex-grow: 0.07 !important;
    }
</style>